//application colors
$text-color: #585857;
$green-color: #3a5f1b;
$blue-color: #185E7B;
$warning-color: #fdda83;
$voting-background-color: #e4dd73;

$ingredients-indicator-good: #A5D322;
$ingredients-indicator-warning: #E29339;
$ingredients-indicator-full: #AB1C1E;


//default colors
$color-white: #ffffff;
$color-grey--dark: #404040;
$color-grey--medium: #c2c2c2;
$color-grey--light: #dbdbdb;
$color-black: #000000;
$color-text: #212529;

//extra effect colors
$shadow-color: $color-grey--medium;
