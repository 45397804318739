.onboarding {
  padding: 0 40px;
  margin-top: 80px;
  flex-direction: column;
  display: flex;
  align-items: center;

  &__skipbutton {
    align-self: flex-end;
  }

  &__cloud {
    background-color: #FFF;
    padding: 10px;
    font-family: 'Gunny-rewritten', sans-serif;
    color: $green-color;
    font-size: $body-large;
    line-height: 20px;
    border: 3px solid $color-black;
    border-radius: 15px;
    max-width: 400px;

    @media (max-width: $breakpoint-mobile) {
      max-width: 300px;
      margin-top: 3 * $spacing;
    }

    &-title {
      color: $blue-color;
      text-transform: uppercase;
      border-bottom: 2px solid $blue-color;
      transform: rotate(-2deg);
      text-align: center;
      margin-bottom: 4 * $spacing;

      h1 {
        font-size: $title-large;
        margin: $spacing;
      }
    }

    &-message {
      text-align: center;
      min-height: 130px;
      align-items: center;
      display: flex;

      @media (max-width: $breakpoint-mobile) {
        min-height: 185px;
      }
    }

    &-speakingtip {
      position: absolute;
      margin-top: 7px;
      margin-left: 150px;
    }
  }

  &__chef {
    margin-top: $spacing * 3;
    @media (max-width: $breakpoint-mobile) {
      text-align: right;
    }
    img {
      width: 300px;
      @media (max-width: $breakpoint-mobile) {
        width: 150px;
      }
    }
  }

}
