.playgroundDashboard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;

  @media (max-width: $breakpoint-mobile) {
    justify-content: space-around;
  };

  a {
    text-decoration: none;
  }

  .button {
    margin-top: $spacing * 2;
  }
}
