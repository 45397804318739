.bottomPaginationBar {
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: $color-white;
  bottom: 0;
  left: 0;
  border-top: 1px solid $color-grey--dark;
  display: flex;
  justify-content: space-around;
  align-items: center;

  &__navigation {
    width: 30px;
    height: 30px;

    img {
      width: 30px;
    }
  }

  &__voting {
    position: absolute;
    margin-top: -80px;

    button {
      img {
        height: 50px;
      }

    }
  }

  &__indicators {
    display: flex;
    align-items: center;

    img:not(:first-child){
      margin-left: 2 * $spacing
    }

    &-passive {
      width: 15px;
      height: 15px;
    }

    &-active {
      width: 25px;
      height: 25px;
    }
  }
}
