.bottomActionBar {
  position: fixed;
  background-color: $color-white;
  border-top: 1px solid $shadow-color;
  height: 60px;
  width: 100%;
  bottom: 0;
  left: 0;
  justify-content: center;
  display: flex;
  align-items: center;
}
