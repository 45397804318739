.checkbox {
    cursor: pointer;

    input[type='checkbox'] {
        width: 0;
        margin: 0;
        height: 0;
        clip: rect(0 0 0 0);
        clip-path: inset(100%);
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
    }

    input[type='checkbox']:checked ~ label {
        text-decoration: underline;
    }

    &-selected {
        display: none;
    }
    &-empty {
        display: block;
    }

    &__image {
        width: 25px;
        height: 25px;
        margin-right: $spacing;
        cursor: pointer;
    }

    &__label {
        display: flex;
        align-items: center;
    }

    input[type='checkbox']:checked ~ {
        .checkbox-selected {
            display: block;
        }
        .checkbox-empty {
            display: none;
        }
    }
}
