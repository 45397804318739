.moodboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    border-bottom: 2px solid $color-black;
  }

  img {
    max-width: 100%;
    width: 100%;
    height: 200px;
  }

  &__mobile-image {
    display: none;

    @media (max-width: $breakpoint-mobile) {
      display: block;
    }
  }

  &__desktop-image {
    display: block;

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }

  }
}
