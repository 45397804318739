.homepage {
    width: 100%;
    margin-top: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__logo {
        img {
            max-width: 300px;
        }
    }

    &__actions {
        margin-top: 20px;
        width: 300px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .button {
            margin: 10px auto;
            width: 200px;
        }
    }
}
