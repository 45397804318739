.login {
    display: flex;
    justify-content: space-between;

    @media (max-width: $breakpoint-mobile) {
        justify-content: center;
        flex-direction: column-reverse;
    }

    &__logo {
        img {
            max-width: 300px;
        }
    }

    &__form {
        width: 300px;

        input {
            margin-bottom: $spacing * 4;
        }
    }

    &__rememberMe {
        margin-bottom: $spacing * 4;
    }

    .button {
        width: 100%;
    }
}
