.quizQuestion {
  background-color: $color-white;
  border-radius: 15px;
  padding: $spacing * 4;
  display: flex;
  flex-direction: column;

  &__header {
    text-align: center;
  }

  &__answer {
    display: flex;
    margin-bottom: $spacing * 2;
    padding: $spacing * 3 0;
    border-bottom: 1px solid $color-black;
    align-items: center;

    &-selected {
      text-decoration: underline;
    }

    img {
      width: 40px;
    }

    &-label {
      flex-grow: 1;
      margin-left: $spacing * 2
    }
  }

  &__button {
    float: right;
    margin-top: $spacing * 4;
    margin-bottom: $spacing * 20;
  }

  &__result {
    text-align: center;
    max-width: 300px;
    img {
      width: 200px;
    }

    h1 {
      border-bottom: 2px solid $color-black;
      margin-bottom: $spacing * 4
    }
  }
}
