.ingredientTile {
  cursor: pointer;
  margin-top: $spacing * 8;
  justify-content: normal;
  min-height: auto;

  &__image {
    margin-bottom: 2 * $spacing;
    max-width: calc(100% - 30px);
  }


  &__actions {
    display:flex;
    align-self: flex-end;
    position: absolute;
    cursor: pointer;
    flex-direction: column;

    .checkbox {
      margin-bottom: $spacing;
    }
  }

  &__title {
    text-align: left;
    font-family: 'Gunny-rewritten', sans-serif;
    text-transform: uppercase;
    font-size: $body-large;
  }

  &__costs {
    text-align: left;
    font-family: 'Gunny-rewritten', sans-serif;
    text-transform: uppercase;
    font-size: $body-large;

    img {
      width: 20px;

      @media (max-width: $breakpoint-mobile-small) {
        width: 12px;
      }
    }
  }


}
