.conceptSelect {
  margin-bottom: 80px;
  &__image {
    border-bottom: 2px solid $color-black;
    margin-bottom: $spacing * 8;
    img {
      max-width: 100%;
    }
  }

  &__vote {

    label {
      display: flex;
      justify-content: space-between;
      height: 50px;
      margin-bottom: $spacing * 4;
    }

    &-title {
      align-self: center;
      height: 100%;
      flex-grow: 1;
      text-align: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      margin-right: 16px;

      background-color: $voting-background-color;
    }
    &-selected {
      text-decoration: underline;
    }
  }

  &__action {
    display: flex;
    justify-content: center;

    .button {
      width: 100%;
      max-width: 300px;
    }
  }

  &__remark {
    align-items: center;
    flex-direction: column;
    display:flex;
    margin-bottom: $spacing * 4;
  }
}
