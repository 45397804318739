.shoppingListReview {
  text-align: center;
  margin-bottom: 80px;

  &__item {
    border-bottom: 2px solid $color-black;
    margin-top: $spacing * 2;

    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 25px;
        height: 25px;
        margin-right: $spacing * 2
      }
    }

    &-multicolumn {
      display: flex;
      flex-wrap: wrap;

    }

    .option {
      margin: 0 auto $spacing * 4 auto;

      img {
        width: 100%;
        max-width: 300px;
      }
    }

    .family img{
      height: 100px;
    }
  }
}
