.chefWarning {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color-black, 0.5);
  z-index: 2;

  &__container {
    position: absolute;
    right: 4 * $spacing;
    bottom: 4 * $spacing;
  }

  &__image {
    float:right;
    img {
      width: 200px;
    }
  }

  &__text {
    background-color: $color-white;
    border-radius: 15px;
    padding: $spacing * 2 $spacing * 4;
    margin-bottom: $spacing * 2;
    max-width: 260px;

    .button {
      width: 100%;
    }
  }
}
