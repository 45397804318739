.navigation {
    background-color: $color-white;
    height: 44px;
    width: calc(100% - #{16 * $spacing});
    border-bottom: 1px solid $shadow-color;
    position: fixed;
    padding: 2 * $spacing 8 * $spacing;
    display: flex;
    justify-content: space-between;
    top: 0;
    z-index: 1;

    &__logoSpeeltuinchef {
        height: 40px;
    }

    &__logoClient {
        height: 40px;
    }

    &__items {
        display: flex;
    }

    &__skiplink {
        opacity: 0;
        width: 0;
    }

    &__skiplink:focus {
        opacity: 1;
        width: auto;
    }

    &__item {
        background-color: $color-grey--light;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        align-items: center;
        display: flex;
        justify-content: center;

        &:not(:first-child){
            margin-left: 2* $spacing;
            z-index: 1;
        }

        img {
            height: 30px;
        }
    }
}
