.shoppingList {
  background-color: $color-white;
  border-style:solid;
  border-image: url('../../assets/images/punch.png') 0 40 / 5 0 5 30px / 1 0 0 0 round;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

  section {
    margin-top: $spacing * 2;
    margin-bottom: $spacing * 10;
  }
}
