.ingredientsPointsIndicator {
  height: 30px;
  background-color: $color-white;
  border-radius: 15px;
  padding: $spacing 2 * $spacing;
  display: flex;
  align-items: center;

  &__sticky {
    position: fixed;
    width: inherit;
    max-width: 686px;
    z-index: 1;
    top: 75px;

    @media (max-width: $breakpoint-mobile-medium) {
      max-width: calc(100% - 48px);
    }
  }

  &__indicator {
    height: 24px;
    border-radius: 12px;
    width: 100%;
    text-align: center;

    &-good {
      background-color: $ingredients-indicator-good;
    }

    &-warning {
      background-color: $ingredients-indicator-warning;
    }

    &-full {
      background-color: $ingredients-indicator-full;
      color: $color-white;
    }
  }

  &__background {
    height: 24px;
    margin-top: -22px;
    border-radius: 12px;
    background-image: url('../../assets/images/coin-stack.svg');
  }
}
