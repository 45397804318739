body {
    background-image: url('../../assets/images/background_structure.png');
    color: $color-text;
    font-size: $body-normal;
    line-height: 1.4 * $body-normal;
}

.container {
    width: 1000px;
    margin: 120px auto 0 auto;

    @media (max-width: $breakpoint-mobile) {
        width: calc(100% - 20px);
        margin: 120px 10px 0 10px;
    }
}

button {
    appearance: none;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
}

.pageLoader {
    display: flex;
    margin-top: $spacing * 20;
    flex-direction: column;
    align-items: center;
    img {
        animation: pulse 2s infinite;
        width: 50px;
    }

    p{
        font-size: 40px;
        font-family: 'Gunny-rewritten', sans-serif;
    }
}

.block {
    margin: 80px auto auto auto;
    width: calc(100% - #{$spacing * 8});
    max-width: 700px;
    padding: $spacing * 4;

    &-topmargin {
        margin-top: 100px;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform:scale(1.1);
    }

}
