.familyMemberCounter {
  display:flex;
  justify-content: space-around;
  align-items: center;

  &__change {
    button {
      font-size: $title-extra-large;
    }
  }

  &__value {
    font-weight: bold;
    color: $green-color;
  }
}
