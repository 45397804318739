.tileExplenation {
  width: calc(100% - #{8*$spacing});
  max-width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 200px;
  }

  h1 {
    border-bottom: 2px solid $color-grey--dark;
    margin-top: 2 * $spacing;
  }

  p {
    text-align: center;
  }

  .button {
    width: 200px;
  }
}
