.button {
    background-color: $green-color;
    color: $color-white;
    padding: 3 * $spacing;
    border-radius: 20px;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    border: 0;
    cursor: pointer;

    img {
        height: 10px;
    }

    &-secondary {
        background-color: $color-white;
        color: $green-color;
        padding: 3 * $spacing;
    }
}
