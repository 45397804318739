.questionList {
  margin-bottom: 80px;
  &__question {
    margin-top: $spacing *8;

    &__text {
      font-weight: bold;
    }

    .checkbox {
      margin-bottom: $spacing;
    }
  }
}
