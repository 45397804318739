
h1 {
  font-family: 'Gunny-rewritten', sans-serif;
  font-size: $title-extra-large;
  line-height: 1.4 * $title-extra-large;
  margin: 0;
}

h2 {
  font-family: 'Gunny-rewritten', sans-serif;
  font-size: $title-large;
  line-height: 1.4 * $title-large;
  margin: 0;
}

h3 {
  font-family: 'Gunny-rewritten', sans-serif;
  font-size: $title-medium;
  line-height: 1.4 * $title-medium;
  margin: 0;
}

@font-face {
  font-family: 'Gunny-rewritten';
  src: url('../../assets/fonts/Gunny-rewritten.woff2') format('woff2'),
  url('../../assets/fonts/Gunny-rewritten.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
