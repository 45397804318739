.sitemap {
  li {
    list-style-type: none;
  }
  &__link {
    position: absolute;
    bottom: $spacing * 5;
    right: $spacing * 2;

    @media screen and (max-width:400px) {
      top: $spacing * 5;
      position: fixed;
      text-align: left;
      left: $spacing * 15;
      z-index: 1;
      bottom: auto;
      right: auto;
    }
  }
}
